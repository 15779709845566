import $ from 'jquery';

$(document).ready(function () {
    function adjustPadding() {
        $('.two-column-intro').each(function () {
            const $container = $(this);
            const $lastDetailGroup = $container.find('.detail__group:last');
            const $svg = $container.next('svg');
            if ($lastDetailGroup.length>0 && $svg.length>0) {
                const detailGroupOffset = $lastDetailGroup.offset().top + $lastDetailGroup.outerHeight();
                const svgOffset = $svg.offset().top + $svg.outerHeight();
                let difference = svgOffset - detailGroupOffset;
                //add bottom padding if svg overlaps with the text
                if (difference < 100) {
                    $container.css('padding-bottom', '10vw');
                } else {
                    $container.css('padding-bottom', '');
                }
            }
        });
    }

    adjustPadding();

  
});
